import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BannerDiv = styled("div")`
  margin-bottom: 30px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  border-style: solid;
  border-width: 3px;
  padding: 2% 5% 2% 5%;
`
const SqBtn = styled("div")`
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: 0 0;
  text-decoration: none;
  font-weight: 500;
  padding: 0.3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  position: relative;
  transition: all 300ms ease 0ms;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 20px;
  width: 100%;
  :hover {
    text-decoration: none;
  }
  :visited {
    text-decoration: none;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
`

const ThePage = () => {
  return (
    <Layout>
      <SEO
        title="抗疫四式 第二式《抹！》HOCI 次亜塩素酸🇯🇵｜櫻花家居服務"
        description="HOCI 次亜塩素酸🇯🇵針對傢私表面消毒👨‍🔬，任何微細部位都可以處理！由施工員人手塗抹，不傷害表面，對細菌有極佳的對抗之效，安全可靠，對人體無害。 👉成份溫和，不刺激皮膚，消毒殺菌力強、無毒、環保，對人體無害。"
      />
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
          padding: 80px 0;
          align-items: center;
        `}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                    color: #f900de;
                  `}
                >
                  第二式《抹！》 HOCI 次亜塩素酸🇯🇵
                </div>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BannerDiv
                css={css`
                  border-color: #f900de;
                  background-color: rgba(249, 0, 222, 0.25);
                `}
              >
                <p>
                  HOCI
                  次亜塩素酸🇯🇵針對傢私表面消毒👨‍🔬，任何微細部位都可以處理！由施工員人手塗抹，不傷害表面，對細菌有極佳的對抗之效，安全可靠，對人體無害。
                </p>
                <p>
                  👉成份溫和，不刺激皮膚，消毒殺菌力強、無毒、環保，對人體無害。
                  <br />
                  👉相同濃度下，比漂白水高80倍殺菌能力。
                  <br />
                  👉得到美國日本台灣專業認證。
                  <br />
                  👉一次性徹底消毒，事後不用清潔擦拭
                  <br />
                </p>
              </BannerDiv>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Link to="/抗疫四式第一式-relyon">
                <SqBtn
                  css={css`
                    color: #fc9201 !important;
                    border-color: #fc9201;
                  `}
                >
                  第一式《噴！》RelyOn Virkon 殺菌劑🇺🇸
                </SqBtn>
              </Link>
              <Link to="/櫻花消毒技術">
                <SqBtn
                  css={css`
                    color: #f900de !important;
                    border-color: #f900de;
                  `}
                >
                  返回「櫻花消毒技術」
                </SqBtn>
              </Link>
            </Col>
            <Col md={6} xs={12}>
              <Link to="/抗疫四式第三式-PIE臭氧消毒裝置">
                <SqBtn
                  css={css`
                    color: #4691f8 !important;
                    border-color: #4691f8;
                  `}
                >
                  第三式《放！》 PIE 臭氧消毒裝置
                </SqBtn>
              </Link>
              <Link to="/抗疫四式第四式-日本東芝光觸媒">
                <SqBtn
                  css={css`
                    color: #01dcbc !important;
                    border-color: #01dcbc;
                  `}
                >
                  第四式《抗！》日本東芝光觸媒
                </SqBtn>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/booking" className="button">
                  <b>立即預約</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/消毒案例" className="button">
                  <b>消毒案例</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
